import Vue from 'vue';
import CompositionApi from '@vue/composition-api';
// import Dialog from '@redware/vue-ui-dialog/src';
// import Crumb from '@redware/vue-hapi-crumb/src';
// import Ui from '@redware/vue-ui/src';
// import Bus from '@redware/vue-bus/src';
import TheLayout from '@/Layout.vue';
Vue.config.productionTip = false;

/**
 * Api de composicion
 */
Vue.use(CompositionApi);
Vue.component('TheLayout', TheLayout);
// Vue.use(Ui);
// Vue.use(Dialog);
// Vue.use(Bus);
// Vue.use(Crumb);
