import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import es from 'vuetify/src/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true
    },
    default: 'light',

    themes: {
      light: {
        primary: '#1867c0',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00'
        // black: '#000000',
      }
    }
  },
  lang: {
    locales: { es },
    current: 'es'
  }
});
